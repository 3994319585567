
















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class RegisterDialog extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  private show!: boolean;

  private backToLogin() {
    this.$emit("backToLogin");
  }

  private nowLogin() {
    this.$emit("nowLogin");
  }
}
