













import { Component,Prop, Vue, Watch } from "vue-property-decorator";
import { Toast } from "vant";
@Component({})
export default class SendButton extends Vue {
  @Prop({
    type: String,
    default: "",
  })
  private item!: string;
  @Prop({
    type: Boolean,
    default: false,
  })
  private isClicking !: boolean;
   @Prop({
    type: Boolean,
    default: false,
  })
  private successFlag!: boolean;
  private num: number = 60;
  private isSend: boolean = true;

  created() {
    console.log("created");
  }

  private onBtnClicked() {
    console.log("isClicking" + this.isClicking)
    if(this.isClicking){
      return
    }
    if (this.item.length == 0) {
      Toast(this.$t("usernameShort").toString());
      return;
    }
    this.$emit("onSendBtnClicked");
  }

  beforeDestroy() {
    console.log("beforeDestroy");
  }
  destroyed() {
    console.log("destroyed");
  }

  @Watch('successFlag')
  private handleWatch(newVal: boolean, OldVal: boolean) {
    console.log("this.isSendSuccess newVal:" + newVal + " OldVal:"+OldVal);
     this.isSend = false;
     console.log('this.numKKK'+ this.num)
      let timer = setInterval(() => {
      this.num--;
      console.log('this.num11:' + this.num)
      if (this.num <= 0) {
        console.log('this.numOO:' + this.num)
        this.isSend = true;
        // this.isClicking = false;
        clearInterval(timer);
        this.num = 60;
      }
    }, 1000);
  }
  private handleWatch2(newVal: boolean) {
    if (newVal) {
      this.isSend = false;
      let timer = setInterval(() => {
      this.num--;
      console.log('this.num11:' + this.num)
      if (this.num <= 0) {
        console.log('this.numOO:' + this.num)
        this.isSend = true;
        clearInterval(timer);
        this.num = 60;
      }
    }, 1000);
    }
  }
}
