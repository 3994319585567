














































































import { Component, Vue } from "vue-property-decorator";
import {
  sendSMS,
  SendSMSReq,
  login,
  LoginReq,
  LoginRes,
  register,
  RegisterReq,
} from "@/api/user";
import { CommonRes, CommonEmailRes } from "@/api/common";
import RegisterDialog from "@/components/RegisterSuccessDialog.vue";
import { Toast } from "vant";
import { checkEmail, checkPassword } from "@/utils/utils";
import SendButton from "@/components/SendButton.vue";
import { globalData } from '@/utils/config';
@Component({
  components: {
    RegisterDialog,
    SendButton,
  },
})
export default class Index extends Vue {
  private show: boolean = false;
  private req: RegisterReq = {
    username: "",
    password: "",
    name: "test11",
    email: "",
    companyCode: "",
    code: "",
  };
  private email: string = "";
  private isSendSuccess: boolean = false;
  private isClickingk: boolean = false;
  private isShowSMSCode: boolean = false;
  created() {
    console.log("register created");
  }

  private back() {
    this.$router.go(-1);
  }

  /**
   * 执行注册
   */
  private async confirmRegister() {
    this.req.username = this.req.username.trim();
    if (this.req.username.length == 0) {
      Toast(this.$t("usernameShort").toString());
      return;
    }
    if (!checkPassword(this.req.password)) {
      Toast(this.$t("passwordError").toString());
      return;
    }
    if (!checkEmail(this.req.email)) {
      Toast(this.$t("emailError").toString());
      return;
    }
    if (this.req.companyCode.length == 0) {
      Toast(this.$t("companyIdError").toString());
      return;
    }
    let res: CommonRes = await register(this.req);
    console.log("confirmRegister", res);
    if (res.code == 0) {
      localStorage.setItem("account", this.req.username);
      localStorage.setItem("id", this.req.username);
      let savePassword = localStorage.getItem("savePassword");
      // if (savePassword != null) {
      //   localStorage.setItem("password", this.req.password);
      // }
      this.show = true;
    }
  }

  private backToLoginEvent() {
    this.show = false;
    this.$router.replace({
      path: "/login",
    });
  }

  private async nowLoginEvent() {
    this.show = false;
    let reqLogin: LoginReq = {
      account: this.req.username,
      password: this.req.password,
      pushId: "",
      languageCode: ""
    };
    let res: LoginRes = await login(reqLogin);
    console.log("confirmLogin", res);
    if (res.code == 0) {
      localStorage.setItem("userInfo", JSON.stringify(res.user));
      localStorage.setItem("token", res.token);
      localStorage.setItem("refresh", "true");
      this.$router.replace({
        path: "/",
      });
    }
  }

  private async sendSMS() {
    this.isClickingk = true;
    let reqSMS: SendSMSReq = {
      email: this.req.email,
      type: "register",
      companyType: "0"
    };
    if (location.host == globalData.yjhUrl) {
      reqSMS.companyType = "1";
    }
    let res: CommonEmailRes = await sendSMS(reqSMS);
    console.log("sendSMS", res);
    if (res.code == 0) {
      this.email = res.email;
      this.isSendSuccess = !this.isSendSuccess;
      this.isShowSMSCode = true
      Toast(this.$t("sendSMSCodeSuccess").toString());
      this.isClickingk = false;
    } else {
      this.isClickingk = false;
    }
  }
}
